// Este archivo se extrajo del GitHub de la librería "react-chatbot-kit" en https://github.com/FredrikOseberg/react-chatbot-kit-docs/tree/master
import React from "react";

import styles from "./Options.module.css";

const Options = ({ options }) => {
  const markup = options.map((option) => (
    <button key={option.id} className={styles.option} onClick={option.handler}>
      {option.name}
    </button>
  ));

  return <div className={styles.options}>{markup}</div>;
};

export default Options;
