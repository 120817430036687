import React from "react";
import { styled } from "@mui/material/styles";

import { Page } from "components";
import { Header, Todos } from "./components";

const PREFIX = "Overview";

const classes = {
  root: `${PREFIX}-root`,
  statistics: `${PREFIX}-statistics`,
  notifications: `${PREFIX}-notifications`,
  projects: `${PREFIX}-projects`,
  todos: `${PREFIX}-todos`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3),
  },

  [`& .${classes.statistics}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.notifications}`]: {
    marginTop: theme.spacing(6),
  },

  [`& .${classes.projects}`]: {
    marginTop: theme.spacing(6),
  },

  [`& .${classes.todos}`]: {
    marginTop: theme.spacing(6),
  },
}));

const Overview = () => {
  return (
    <StyledPage className={classes.root} title="Overview">
      <Header />

      <Todos className={classes.todos} />
    </StyledPage>
  );
};
export default Overview;
