import React, { useState, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ListItem, Button, Collapse, colors, Avatar } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const PREFIX = "NavigationListItem";

const classes = {
  item: `${PREFIX}-item`,
  itemLeaf: `${PREFIX}-itemLeaf`,
  button: `${PREFIX}-button`,
  buttonLeaf: `${PREFIX}-buttonLeaf`,
  icon: `${PREFIX}-icon`,
  expandIcon: `${PREFIX}-expandIcon`,
  newItems: `${PREFIX}-newItems`,
  active: `${PREFIX}-active`,
  warningIcon: `${PREFIX}-warningIcon`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.itemLeaf}`]: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.button}`]: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },

  [`& .${classes.buttonLeaf}`]: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.expandIcon}`]: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },

  [`& .${classes.newItems}`]: {
    marginLeft: theme.spacing(11),
  },

  [`& .${classes.active}`]: {
    color: "#802e87",
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: "#802e87",
    },
  },

  [`& .${classes.warningIcon}`]: {
    backgroundColor: colors.red[600],
    height: 20,
    width: 20,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const NavigationListItem = (props) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    ...rest
  } = props;

  const [open, setOpen] = useState(openProp);
  const userData = useSelector((state) => state.userData.serverUserData);

  const hasUnReadAlerts =
    userData.profile.unread_messages > 0 || userData.profile.unread_alerts > 0;

  const handleToggle = () => {
    setOpen(!open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <StyledListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </StyledListItem>
    );
  } else {
    return (
      <StyledListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters
      >
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={style}
          to={href}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
        </Button>

        {hasUnReadAlerts && title === "Avisos" ? (
          <Avatar className={classes.warningIcon}>!</Avatar>
        ) : null}

        {title === "Autoreporte" && !userData.answered_today ? (
          <Avatar className={classes.warningIcon}>!</Avatar>
        ) : null}
      </StyledListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default NavigationListItem;
