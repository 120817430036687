import { CHATBOT_URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function askChatbot(message) {
  const response = await fetch(`${CHATBOT_URL}`, {
    method: "POST",
    body: JSON.stringify(message),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
