const paths = {
  frente_izq: {
    path: "M4333 13644 c-17 -26 -18 -59 -18 -514 1 -473 5 -560 27 -560 6 0 26 14 45 31 41 35 68 35 68 -1 0 -20 -11 -30 -64 -56 -71 -35 -79 -47 -80 -114 -1 -74 4 -77 98 -55 84 19 103 17 81 -10 -21 -25 -68 -46 -118 -54 -42 -6 -47 -9 -55 -41 -10 -38 -4 -266 7 -277 14 -14 123 54 182 113 66 66 130 184 194 357 18 48 38 87 44 87 6 0 25 -20 41 -45 48 -71 62 -70 100 5 66 130 95 289 61 335 -13 18 -15 18 -59 -8 -40 -24 -87 -36 -87 -23 0 3 9 20 20 39 28 47 60 179 60 246 0 120 -55 270 -130 354 -80 90 -254 192 -364 212 -30 6 -37 3 -53 -21z",
  },
  frente_der: {
    path: "M4104 13636 c-45 -18 -102 -47 -126 -64 -96 -66 -170 -175 -199 -292 -21 -85 -16 -261 9 -344 12 -38 19 -73 15 -78 -3 -6 -27 -7 -57 -4 -78 10 -86 4 -86 -63 0 -100 63 -298 97 -309 8 -2 15 12 19 37 4 25 12 41 20 41 16 0 44 -53 69 -130 43 -132 136 -284 225 -363 52 -47 128 -84 153 -74 17 6 27 91 27 223 l0 94 -48 0 c-55 0 -135 36 -140 63 -5 23 16 22 93 -5 76 -27 86 -22 92 49 7 70 -8 99 -53 107 -21 4 -50 20 -67 37 -27 27 -28 32 -15 45 13 14 20 13 57 -6 22 -12 47 -20 55 -17 20 8 29 242 24 667 -5 459 -2 453 -164 386z",
  },
  cuello_frontal: {
    path: "M4626 12175 c-36 -69 -115 -144 -197 -186 -58 -30 -73 -34 -149 -34 -76 0 -92 3 -151 32 -36 18 -95 60 -131 93 l-65 59 -5 -77 c-6 -97 -28 -141 -107 -214 -96 -90 -340 -226 -434 -243 -68 -13 -56 -34 33 -59 95 -26 207 -37 465 -45 197 -7 258 -12 287 -25 50 -22 212 -22 293 0 33 9 76 20 95 25 19 6 163 14 320 18 288 9 358 18 467 62 l28 12 -44 8 c-98 19 -329 154 -448 263 -86 78 -170 207 -203 311 -7 22 -15 43 -19 47 -3 4 -19 -17 -35 -47z",
  },
  cuello_atras: {
    path: "M14314 12269 c-100 -55 -188 -72 -339 -66 -119 5 -199 24 -307 71 -44 19 -39 28 -62 -112 -37 -217 -129 -309 -523 -523 -35 -18 -63 -37 -63 -40 0 -25 312 -42 1065 -60 561 -13 839 -1 852 37 2 6 -49 39 -114 74 -332 178 -405 272 -420 538 -3 62 -9 114 -12 116 -3 2 -38 -14 -77 -35z",
  },
  pectoral_izq: {
    path: "M5490 11554 c-85 -13 -155 -29 -249 -57 l-106 -32 -210 3 c-287 3 -409 -12 -441 -56 -7 -9 -36 -16 -82 -20 l-72 -5 2 -456 3 -456 575 1 c316 0 592 4 612 8 29 5 40 14 55 44 10 20 45 58 77 84 64 51 312 181 464 243 66 27 96 45 102 60 21 56 -40 259 -120 395 -96 164 -270 251 -495 248 -49 0 -101 -2 -115 -4z",
  },
  pectoral_der: {
    path: "M2910 11535 c-91 -25 -178 -74 -227 -129 -90 -100 -218 -349 -230 -444 -7 -63 2 -71 119 -111 122 -43 253 -111 347 -181 82 -61 108 -92 126 -155 l12 -40 618 -3 618 -2 -7 267 c-7 299 -22 577 -32 617 -5 20 -12 26 -33 26 -14 0 -44 12 -65 26 -34 23 -54 28 -150 35 -61 4 -169 8 -241 9 -163 1 -228 10 -385 56 -113 33 -139 37 -270 40 -108 3 -159 0 -200 -11z",
  },
  brazo_izq: {
    path: "M6095 10802 c-271 -112 -399 -195 -447 -291 -45 -88 -40 -114 43 -217 38 -49 113 -154 166 -234 140 -213 190 -286 257 -375 107 -143 208 -256 243 -273 32 -15 40 -15 113 0 149 31 269 109 319 207 27 52 26 93 -4 166 -14 33 -34 96 -45 140 -27 107 -136 323 -283 557 -64 103 -140 230 -168 283 -28 52 -52 95 -52 95 -1 0 -65 -27 -142 -58z",
  },
  brazo_der: {
    path: "M2412 10818 c-5 -7 -39 -74 -74 -148 -36 -74 -92 -182 -127 -239 -64 -108 -98 -179 -151 -321 -51 -136 -155 -340 -209 -414 -28 -38 -51 -74 -51 -81 0 -23 58 -92 108 -129 64 -46 194 -107 292 -136 l75 -22 88 93 c140 149 374 462 475 634 56 96 121 263 140 357 27 140 -8 188 -227 302 -196 101 -312 137 -339 104z",
  },
  costado_der: {
    path: "M3075 10388 c124 -545 147 -709 165 -1166 6 -150 15 -284 20 -297 8 -21 16 -25 47 -25 35 0 46 8 138 102 124 127 263 299 321 398 41 70 60 113 157 370 80 210 206 360 301 360 58 0 61 7 64 150 l4 130 -611 0 -611 0 5 -22z",
  },
  costado_izq: {
    path: "M4331 10303 c1 -119 7 -153 29 -163 8 -4 35 -16 60 -28 25 -11 69 -39 98 -62 64 -51 92 -107 187 -370 81 -224 175 -421 245 -513 67 -89 163 -159 321 -235 4 -1 9 98 12 220 6 259 9 273 121 650 75 253 129 458 141 541 6 37 4 46 -12 53 -10 4 -285 10 -610 12 l-593 3 1 -108z",
  },
  abdomen_der: {
    path: "M4157 10049 c-72 -48 -105 -105 -200 -344 -143 -364 -179 -420 -431 -682 -107 -112 -176 -168 -219 -178 -16 -4 -35 -18 -44 -31 -22 -33 -56 -285 -49 -359 8 -75 51 -168 115 -249 63 -79 227 -237 366 -354 l110 -92 216 0 216 0 7 438 c3 240 11 698 17 1017 6 319 7 644 4 723 l-7 142 -27 0 c-15 0 -49 -14 -74 -31z",
  },
  abdomen_izq: {
    path: "M4346 10071 c-9 -14 -25 -794 -32 -1583 l-7 -728 285 0 284 0 231  233 c126 127 238 247 248 266 15 30 16 44 6 125 -6 50 -18 166 -26 258 -19  218 -23 228 -113 262 -126 48 -246 139 -330 249 -22 29 -43 63 -47 77 -4 14  -16 34 -26 45 -34 37 -107 209 -172 402 -81 241 -119 308 -211 374 -38 29 -80  37 -90 20z",
  },
  antebrazo_der: {
    path: "M1662 9493 c-132 -148 -207 -283 -382 -693 -51 -118 -119 -267 -151  -330 -93 -180 -232 -410 -288 -472 -32 -36 -49 -62 -45 -72 13 -34 132 -95  224 -115 80 -17 114 -7 153 48 19 25 99 116 178 201 79 86 182 198 229 250 47  52 139 154 205 225 262 283 449 573 450 698 0 56 1 56 -100 87 -131 42 -266  122 -350 209 -21 23 -42 41 -46 41 -4 0 -38 -35 -77 -77z",
  },
  antebrazo_izq: {
    path: "M6833 9583 c-63 -82 -110 -126 -163 -153 -66 -33 -181 -75 -232 -84  -21 -4 -38 -11 -38 -15 0 -10 109 -243 147 -316 80 -153 241 -358 369 -471  116 -102 194 -182 346 -354 75 -85 174 -195 220 -245 l84 -89 59 48 c60 48  159 103 228 125 25 9 37 19 37 31 0 10 -34 60 -76 112 -167 203 -367 533 -549  903 -67 138 -143 278 -169 313 -56 75 -206 232 -223 232 -7 0 -25 -16 -40 -37z",
  },
  muslo_izq: {
    path: "M5012 7833 c-393 -392 -484 -501 -566 -672 -26 -53 -54 -109 -64  -126 -14 -26 -17 -71 -20 -320 -3 -257 -1 -304 17 -415 26 -159 52 -396 91  -820 39 -438 41 -460 45 -460 2 0 39 -7 82 -15 215 -41 424 7 561 130 65 58  105 139 133 265 30 140 66 280 104 408 86 283 135 632 150 1057 12 329 -22  705 -100 1128 -32 172 -38 191 -58 195 -11 2 -141 -120 -375 -355z",
  },
  mano_izq: {
    path: "M10635 7782 c-230 -128 -551 -441 -581 -566 -7 -28 -6 -29 25 -23 38  8 92 44 186 126 107 93 133 80 70 -34 -20 -34 -46 -94 -59 -131 -13 -38 -44  -119 -69 -180 -52 -125 -72 -196 -63 -220 15 -39 79 55 195 282 63 125 94 165  111 148 7 -7 6 -24 -4 -50 -17 -51 -118 -253 -162 -325 -28 -47 -34 -67 -34  -114 0 -73 15 -70 56 14 17 35 37 69 46 75 8 6 20 26 26 44 21 62 159 252 183  252 16 0 10 -24 -16 -63 -71 -103 -134 -258 -150 -367 l-7 -45 27 25 c40 36  75 91 140 215 75 143 115 189 115 130 0 -14 -25 -80 -55 -146 -90 -197 -86  -215 23 -86 193 228 319 436 388 642 53 158 54 153 -81 263 -123 100 -195 148  -235 157 -17 3 -42 -4 -75 -23z",
  },
  mano_der: {
    path: "M17332 7796 c-83 -78 -135 -112 -207 -136 -86 -29 -89 -33 -70 -116  43 -187 118 -356 209 -465 73 -88 274 -302 292 -312 61 -34 -9 93 -109 199  -62 66 -76 85 -63 90 30 11 93 -43 190 -163 98 -123 191 -223 207 -223 20 0 6  31 -66 149 -42 68 -82 138 -89 155 -7 17 -31 51 -53 76 -37 42 -63 81 -63 94  0 3 9 6 19 6 30 0 122 -110 176 -209 27 -50 74 -125 104 -168 60 -83 91 -103  91 -59 0 38 -39 119 -146 302 -92 158 -105 195 -62 179 22 -9 120 -148 161  -230 35 -70 91 -135 104 -122 11 12 -12 68 -118 285 -54 112 -100 216 -101  230 -4 40 29 39 88 -2 134 -96 168 -116 195 -116 24 0 29 4 29 23 0 36 -44 89  -191 232 -148 143 -270 240 -361 286 -32 16 -66 39 -76 49 -9 11 -20 20 -25  20 -5 0 -34 -24 -65 -54z",
  },
  palma_izq: {
    path: "M7855 7976 c-208 -86 -245 -119 -245 -222 0 -81 24 -206 50 -259 11  -23 50 -80 87 -126 36 -46 109 -145 161 -219 103 -145 176 -230 198 -230 35 0  5 73 -85 210 -51 76 -60 96 -46 98 22 5 53 -25 143 -138 165 -207 192 -233  192 -177 -1 33 -33 98 -96 194 -90 137 -123 224 -79 206 8 -3 69 -91 136 -197  66 -105 128 -197 137 -203 24 -19 62 -16 62 4 0 35 -91 201 -161 293 -87 115  -113 156 -101 164 29 18 163 -139 244 -286 24 -43 52 -80 62 -84 26 -9 49 23  41 56 -24 105 -251 434 -324 468 -45 22 -44 36 1 40 43 4 76 -12 162 -75 115  -86 237 -128 252 -88 10 25 -43 93 -176 226 -75 76 -156 146 -200 174 -41 27  -129 83 -196 127 -66 43 -125 78 -130 78 -5 -1 -45 -16 -89 -34z",
  },
  palma_der: {
    path: "M646 7889 c-52 -21 -193 -112 -236 -151 -14 -13 -57 -61 -96 -108  -40 -47 -86 -98 -104 -115 -39 -36 -60 -75 -60 -110 0 -48 28 -41 88 22 71 74  200 143 268 143 57 0 57 -17 -3 -55 -38 -25 -62 -56 -135 -175 -49 -80 -102  -172 -118 -205 -34 -68 -39 -122 -13 -132 21 -8 66 42 175 192 76 104 103 133  115 122 11 -11 -12 -53 -104 -190 -115 -171 -150 -241 -132 -263 14 -18 45  -10 77 20 39 35 149 206 191 297 21 46 48 92 59 102 22 20 42 16 42 -9 0 -23  -101 -211 -168 -313 -59 -89 -73 -121 -53 -121 19 0 73 54 112 112 22 32 74  98 116 146 41 48 86 110 100 137 35 68 54 80 73 45 17 -31 -19 -88 -119 -194  -108 -113 -156 -196 -115 -196 29 0 124 91 223 213 128 159 184 245 225 347  48 120 84 266 69 281 -6 6 -46 15 -89 20 -93 10 -156 39 -239 109 -32 27 -68  50 -80 49 -11 0 -42 -9 -69 -20z",
  },
  pubis: {
    path: "M3893 7693 c-18 -2 -33 -7 -33 -11 1 -4 19 -25 41 -47 36 -36 90  -112 231 -330 25 -39 71 -100 103 -135 31 -36 64 -81 72 -100 15 -33 16 -34  30 -15 8 11 33 56 55 100 78 154 161 270 326 453 l84 92 -439 -2 c-241 0 -453  -3 -470 -5z",
  },
  muslo_der: {
    path: "M3142 8289 c-28 -92 -79 -327 -94 -439 -10 -69 -13 -258 -13 -700 1  -734 13 -883 95 -1230 29 -120 194 -653 228 -734 25 -60 133 -161 216 -201  177 -86 507 -57 551 48 41 98 155 1451 155 1840 0 148 -4 159 -95 272 -30 39  -101 138 -157 220 -186 275 -215 308 -464 531 -206 184 -298 281 -344 363 -60  105 -55 103 -78 30z",
  },
  rodilla_der: {
    path: "M3373 4815 c4 -138 7 -343 7 -457 l0 -206 68 34 c176 89 377 86 570  -8 34 -17 64 -29 67 -27 2 3 16 95 30 204 14 110 30 235 36 279 12 92 17 301  8 311 -4 3 -49 -5 -100 -20 -124 -34 -294 -43 -396 -22 -76 16 -162 60 -247  124 l-49 37 6 -249z",
  },
  rodilla_izq: {
    path: "M5172 5081 c-119 -96 -255 -141 -422 -141 -58 0 -133 6 -168 14 -49  10 -64 10 -73 0 -5 -7 -14 -63 -19 -126 -14 -178 -24 -618 -14 -618 5 0 43 11  84 24 103 33 254 49 420 44 126 -3 142 -5 170 -27 17 -12 33 -21 35 -19 2 1 8  163 14 358 9 294 14 370 30 440 11 47 21 88 21 93 0 15 -19 5 -78 -42z",
  },
  pierna_izq: {
    path: "M4760 4220 c-193 -11 -290 -58 -305 -146 -4 -21 -16 -86 -28 -144  -19 -91 -21 -137 -21 -345 1 -280 11 -367 58 -507 62 -186 98 -389 117 -660  15 -205 2 -389 -41 -603 -16 -81 -30 -159 -30 -173 0 -24 2 -24 48 -18 86 12  266 26 350 26 69 0 85 3 102 20 26 26 26 79 0 173 -25 89 -27 238 -5 365 8 49  38 184 65 299 70 296 99 479 161 1023 25 216 10 562 -27 618 -42 64 -183 87  -444 72z",
  },
  pierna_der: {
    path: "M3585 4186 c-114 -28 -226 -107 -245 -174 -15 -55 -12 -342 5 -505  15 -138 47 -335 119 -723 75 -404 108 -772 87 -964 -6 -61 -14 -124 -18 -141  l-5 -29 252 0 253 0 -6 83 c-3 45 -15 118 -27 161 -21 77 -28 212 -15 268 3  13 18 150 35 306 35 331 47 412 100 662 59 283 64 328 45 473 -28 217 -64 419  -82 456 -49 98 -325 169 -498 127z",
  },
  empeine_der: {
    path: "M3517 1578 c-8 -12 -26 -43 -40 -68 -36 -62 -120 -165 -241 -295  -143 -154 -197 -227 -220 -302 -11 -34 10 -29 61 17 55 48 85 55 58 13 -10  -15 -30 -37 -44 -49 -31 -26 -68 -90 -58 -100 8 -8 129 97 160 138 26 35 47  36 47 2 0 -25 -20 -49 -108 -127 -24 -22 -32 -37 -32 -63 0 -31 2 -34 19 -24  28 14 128 134 151 181 14 25 27 39 40 39 42 0 11 -70 -70 -155 -51 -54 -66  -83 -46 -91 33 -13 60 1 69 35 4 18 15 44 24 58 23 34 171 163 188 163 11 0  15 -12 15 -40 0 -27 5 -43 15 -46 24 -10 18 -62 -11 -90 -21 -22 -31 -25 -67  -21 -23 3 -53 7 -67 9 -31 4 -37 -21 -12 -52 l19 -23 99 17 c200 35 219 45  268 145 36 73 82 135 199 266 54 60 109 133 123 162 31 63 34 142 10 227 -26  90 -29 90 -302 94 l-232 4 -15 -24z",
  },
  empeine_izq: {
    path: "M4671 1589 c-114 -15 -171 -30 -199 -54 -27 -23 -27 -25 -27 -147 0  -107 3 -128 21 -163 25 -46 78 -87 179 -138 102 -51 152 -90 290 -225 104  -102 129 -122 161 -127 22 -3 75 -12 119 -21 44 -8 116 -22 160 -30 145 -27  275 9 275 77 0 14 -33 11 -75 -6 -20 -9 -45 -13 -55 -10 -19 6 -40 59 -40 102  0 13 -21 46 -50 78 -49 54 -63 85 -37 85 14 0 43 -20 133 -91 33 -25 82 -58  109 -73 28 -15 61 -41 76 -58 22 -27 26 -28 38 -14 21 25 8 40 -77 85 -84 43  -186 128 -197 162 -8 24 10 49 35 49 9 0 49 -23 89 -51 40 -28 75 -48 78 -45  10 10 -38 75 -87 119 -32 29 -66 49 -96 56 -109 28 -179 81 -276 212 -71 95  -175 194 -230 219 -35 16 -64 19 -148 19 -58 -1 -134 -5 -169 -10z",
  },
  cabeza_der: {
    path: "M14000 12947 l0 -704 63 9 c152 20 298 88 332 154 33 64 45 67 86 23  l30 -32 29 28 c83 79 132 313 81 376 l-19 24 -32 -23 c-17 -13 -35 -21 -38  -17 -4 4 -7 111 -7 238 l0 232 -37 70 c-68 127 -166 227 -267 272 -56 25 -160  53 -198 53 l-23 0 0 -703z",
  },
  cabeza_izq: {
    path: "M13780 13624 c-200 -68 -312 -184 -355 -366 -26 -113 -18 -213 35  -393 11 -38 20 -78 20 -87 0 -28 -36 -22 -59 10 -38 50 -43 53 -69 50 -66 -8  -61 -105 12 -253 71 -144 186 -255 186 -181 0 29 26 58 49 54 11 -2 17 -18 21  -53 5 -45 8 -51 45 -72 52 -30 147 -60 213 -68 l52 -7 -1 659 c0 482 -3 667  -12 693 -16 45 -39 48 -137 14z",
  },
  hombro_izq: {
    path: "M12707 11540 c-113 -24 -178 -63 -271 -161 -133 -142 -226 -337 -297  -620 l-41 -163 33 -29 c72 -61 271 -176 318 -184 25 -4 136 -8 246 -8 174 0  204 2 228 18 14 9 37 17 49 17 30 0 158 56 256 112 42 24 170 105 284 181 243  160 268 173 368 203 l75 22 3 286 2 286 -187 0 c-237 0 -525 16 -668 37 -165  25 -294 26 -398 3z",
  },
  hombro_der: {
    path: "M15025 11539 c-51 -31 -182 -39 -613 -39 l-413 0 3 -280 c3 -312 -1  -293 75 -305 73 -11 263 -74 353 -118 47 -23 182 -106 300 -186 236 -158 332  -208 460 -241 209 -53 336 -28 468 94 48 44 212 239 212 252 0 4 -18 55 -41  112 -22 58 -47 136 -55 174 -35 169 -171 346 -324 423 -58 29 -306 115 -370  128 -15 3 -37 -3 -55 -14z",
  },
  dorsal_der: {
    path: "M14000 10337 l0 -525 149 -144 c246 -239 342 -309 620 -447 146 -73  228 -107 237 -98 3 3 11 31 19 63 8 33 37 131 65 219 88 276 160 590 160 701  0 42 -29 108 -75 169 -17 21 -69 55 -153 100 -144 76 -289 168 -382 243 -80  65 -299 174 -411 206 -47 14 -118 28 -158 32 l-71 7 0 -526z",
  },
  dorsal_izq: {
    path: "M13862 10849 c-50 -9 -124 -50 -214 -116 -175 -129 -463 -305 -523  -319 -16 -4 -39 -13 -50 -20 -11 -7 -46 -22 -77 -34 -64 -24 -100 -60 -161  -161 -37 -61 -38 -67 -34 -129 3 -36 26 -153 51 -261 63 -276 106 -547 106  -678 0 -22 2 -23 48 -16 124 18 272 91 436 213 72 53 167 123 211 155 139 99  263 234 287 312 5 16 12 263 15 548 l6 517 -29 -1 c-16 -1 -48 -5 -72 -10z",
  },
  tricep_der: {
    path: "M15795 10529 c-170 -194 -260 -241 -457 -240 -60 0 -108 -2 -108 -6  0 -11 265 -404 372 -550 142 -195 298 -392 319 -404 42 -22 201 31 289 98 95  71 182 163 174 182 -4 9 -42 118 -85 243 -85 252 -134 356 -262 562 -47 76  -94 158 -103 182 -9 24 -22 44 -29 44 -7 0 -57 -50 -110 -111z",
  },
  tricep_izq: {
    path: "M12084 10496 c-20 -31 -213 -498 -243 -588 -12 -36 -46 -104 -76  -151 -30 -48 -55 -97 -55 -110 0 -25 14 -37 210 -167 165 -109 235 -150 259  -150 28 0 87 76 145 189 26 50 106 173 178 274 181 252 338 488 338 509 0 3  -75 8 -167 11 -233 9 -282 24 -448 132 -122 80 -122 80 -141 51z",
  },
  lumbar_der: {
    path: "M14000 9182 l0 -551 162 -135 c90 -75 178 -143 197 -152 30 -14 44  -15 130 -3 84 12 284 14 471 6 54 -2 55 -1 58 27 2 16 -4 70 -13 120 -10 55  -20 181 -25 321 -4 127 -11 237 -14 246 -4 9 -57 37 -119 63 -260 110 -459  247 -736 505 l-111 103 0 -550z",
  },
  lumbar_izq: {
    path: "M13913 9665 c-47 -73 -108 -128 -299 -268 -278 -204 -368 -256 -547  -318 l-98 -34 3 -352 c2 -193 6 -354 9 -357 3 -3 40 3 82 14 162 41 262 37  455 -17 l94 -26 172 174 172 174 -6 530 -5 530 -32 -50z",
  },
  coxis: {
    path: "M13908 8513 c-42 -38 -113 -108 -157 -156 l-81 -89 48 -56 c27 -32  97 -125 156 -208 112 -159 146 -198 153 -177 9 24 117 179 188 269 75 95 121  176 110 193 -10 16 -324 291 -333 291 -4 0 -42 -30 -84 -67z",
  },
  nalga_der: {
    path: "M14611 8309 c-76 -10 -194 -44 -227 -66 -13 -8 -49 -52 -81 -97 -31  -44 -94 -130 -141 -189 l-84 -108 10 -416 9 -416 24 -19 c46 -36 198 -106 256  -118 37 -8 108 -11 198 -7 169 6 233 23 359 99 160 97 256 222 256 332 0 64  -30 249 -76 476 -20 96 -46 245 -58 330 l-22 154 -59 18 c-119 35 -235 44  -364 27z",
  },
  nalga_izq: {
    path: "M13140 8314 c-135 -25 -173 -50 -195 -132 -9 -31 -36 -124 -60 -207  -72 -247 -106 -427 -120 -640 -12 -185 27 -302 139 -422 135 -145 343 -192  629 -143 194 33 334 95 412 182 69 76 69 76 73 446 l3 334 -59 66 c-32 37 -91  112 -130 167 -39 55 -95 133 -123 173 -93 130 -189 171 -409 177 -74 2 -146 2  -160 -1z",
  },
  bicep_fem_der: {
    path: "M15165 7110 c-57 -78 -155 -153 -279 -215 -135 -67 -196 -78 -407  -73 l-149 3 -119 62 -119 61 -8 -176 c-20 -395 -6 -750 36 -962 22 -108 36  -234 45 -400 3 -63 15 -196 26 -295 17 -152 19 -234 17 -525 -2 -190 1 -369 6  -399 l8 -54 137 6 c133 5 350 40 457 72 l51 15 7 103 c12 166 26 286 46 377  10 47 31 162 45 255 33 210 51 290 120 522 61 205 95 370 122 583 15 120 18  222 18 595 0 297 -4 458 -11 474 -10 23 -11 23 -49 -29z",
  },
  bicep_fem_izq: {
    path: "M12763 6650 c9 -487 36 -772 102 -1050 14 -58 63 -217 109 -355 120  -356 122 -364 118 -690 -1 -167 2 -290 9 -323 l10 -52 367 2 367 3 12 70 c8  45 11 168 10 350 -2 236 1 293 16 365 10 47 22 164 28 260 5 96 16 256 25 355  8 99 22 263 29 365 17 209 42 947 33 956 -3 3 -28 -10 -55 -29 -144 -103 -366  -160 -628 -161 -108 0 -154 4 -200 18 -126 38 -240 113 -302 200 -20 28 -41  56 -46 61 -6 6 -7 -120 -4 -345z",
  },
  gemelo_der: {
    path: "M14630 4119 c-80 -16 -196 -32 -258 -36 -62 -3 -115 -10 -118 -15 -3  -5 -7 -48 -9 -96 -2 -48 -13 -132 -24 -186 -27 -128 -69 -416 -77 -532 -8  -114 3 -195 71 -505 67 -301 75 -386 75 -786 l0 -313 123 0 c67 0 140 5 162  11 78 21 90 45 120 241 8 59 31 162 50 230 63 227 109 487 147 828 28 263 37  811 14 1001 -21 184 -23 189 -83 188 -27 0 -113 -14 -193 -30z",
  },
  gemelo_izq: {
    path: "M13126 4058 c-3 -35 -18 -121 -33 -193 -37 -181 -39 -474 -5 -740 26  -200 63 -410 92 -526 64 -249 131 -862 106 -967 l-8 -32 221 0 c149 0 221 3  221 11 0 5 -5 42 -11 81 -23 148 36 729 111 1093 41 199 50 276 50 449 0 113  -5 180 -21 262 -17 86 -22 162 -26 367 l-6 257 -342 0 -342 0 -7 -62z",
  },
  tobillo_der: {
    path: "M14605 1625 c-43 -14 -122 -28 -206 -35 l-135 -13 -13 -121 c-7 -66  -15 -143 -18 -170 l-6 -49 69 6 c131 13 304 54 352 84 54 33 116 109 108 132 -34 105 -63 181 -71 184 -5 3 -42 -6 -80 -18z",
  },
  tobillo_izq: {
    path: "M13259 1443 c-12 -54 -23 -123 -23 -153 l-1 -55 50 -17 c70 -23 219  -38 375 -38 l132 0 -6 28 c-7 32 -46 301 -46 320 0 9 -53 12 -229 12 l-229 0  -23 -97z",
  },
  talon_der: {
    path: "M14675 1274 c-130 -48 -267 -84 -358 -93 -110 -11 -131 -28 -142  -116 -20 -153 53 -295 167 -326 52 -14 119 -3 260 40 123 38 294 76 437 96  236 34 343 76 393 154 39 63 40 131 0 159 -34 25 -114 48 -212 61 -85 12 -442  51 -462 51 -7 0 -44 -12 -83 -26z",
  },
  talon_izq: {
    path: "M12722 1363 c-31 -6 -42 -40 -42 -127 0 -63 4 -78 28 -115 35 -52  118 -121 192 -160 30 -16 116 -54 191 -84 75 -30 211 -90 303 -131 188 -86  243 -101 316 -85 67 14 111 46 138 99 38 77 28 174 -34 308 l-34 77 -154 -4  c-229 -5 -378 24 -444 86 -29 28 -34 38 -29 63 l7 30 -63 0 c-34 0 -96 9 -137  20 -77 21 -197 32 -238 23z",
  },
  antebrazo_pos_der: {
    path: "M16335 9469 c-94 -86 -174 -139 -266 -176 -38 -15 -69 -32 -69 -37 0  -6 18 -31 40 -57 58 -68 121 -179 215 -374 117 -243 243 -425 467 -673 61 -67  135 -153 165 -190 62 -77 123 -192 123 -232 0 -49 21 -54 97 -24 108 42 243  149 243 192 0 6 -32 59 -72 119 -91 138 -146 254 -248 528 -91 246 -209 494  -299 630 -74 113 -205 270 -269 324 l-49 41 -78 -71z",
  },
  antebrazo_pos_izq: {
    path: "M11636 9556 c-14 -24 -55 -81 -91 -127 -88 -109 -196 -292 -305 -514  -110 -224 -158 -336 -250 -590 -89 -245 -140 -353 -203 -421 l-48 -52 91 -62  c50 -35 129 -96 176 -137 47 -41 87 -73 89 -71 1 1 40 77 85 168 128 255 265  459 529 785 144 178 226 307 339 534 l97 193 -32 27 c-17 14 -98 69 -180 121  -81 52 -176 116 -210 142 l-62 46 -25 -42z",
  },
};
export default paths;
