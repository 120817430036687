import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography, Grid, Button, Hidden } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Link as RouterLink } from "react-router-dom";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  summaryButton: `${PREFIX}-summaryButton`,
  barChartIcon: `${PREFIX}-barChartIcon`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.summaryButton}`]: {
    backgroundColor: theme.palette.white,
  },

  [`& .${classes.barChartIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.image}`]: {
    width: "100%",
    maxHeight: 400,
  },
}));

const Header = (props) => {
  const { className, ...rest } = props;

  const userData = useSelector((state) => state.userData.serverUserData);
  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Home
          </Typography>
          <Typography component="h1" gutterBottom variant="h3">
            Buenos Días, {userData.first_name}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Veamos como vas hoy
          </Typography>
          <Button
            className={classes.summaryButton}
            edge="start"
            variant="contained"
            component={RouterLink}
            to={"/profile"}
          >
            <BarChartIcon className={classes.barChartIcon} />
            Ver Estadisticas
          </Button>
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            <img
              alt="Cover"
              className={classes.image}
              src="/images/undraw_growth_analytics_8btt.svg"
            />
          </Grid>
        </Hidden>
      </Grid>
    </Root>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
