import React from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

import { Page } from "components";
import {
  Header,
  Overview,
  PainBarGraph,
  SleepBarGraph,
  MoodBarGraph,
  BodyGraph,
} from "./components";

const PREFIX = "DashboardAnalytics";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3),
  },

  [`& .${classes.container}`]: {
    "& > *": {
      height: "100%",
    },
  },
}));

const DashboardAnalytics = () => {
  return (
    <StyledPage className={classes.root}>
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        <Grid item lg={6} xs={12}>
          <PainBarGraph />
        </Grid>

        <Grid item lg={6} xs={12}>
          <SleepBarGraph />
        </Grid>

        <Grid item lg={6} xs={12}>
          <MoodBarGraph />
        </Grid>
        <Grid item lg={6} xs={12}>
          <BodyGraph />
        </Grid>
      </Grid>
    </StyledPage>
  );
};

export default DashboardAnalytics;
