import React from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = (props) => {
  const { handleSearchChange, value } = props;

  function handleChange(event) {
    if (!handleSearchChange) return;
    handleSearchChange(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <TextField
        placeholder="Buscar..."
        color="secondary"
        style={{
          backgroundColor: "white",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={value}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default SearchBar;
