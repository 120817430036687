import React, { Suspense, useState } from "react";
import { styled } from "@mui/material/styles";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";
import { NavBar, TopBar } from "./components";
import { ErrorBoundary } from "react-error-boundary";

const PREFIX = "Dashboard";

const classes = {
  root: `${PREFIX}-root`,
  topBar: `${PREFIX}-topBar`,
  container: `${PREFIX}-container`,
  navBar: `${PREFIX}-navBar`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },

  [`& .${classes.topBar}`]: {
    zIndex: 2,
    position: "relative",
    boxShadow: true,
  },

  [`& .${classes.container}`]: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },

  [`& .${classes.navBar}`]: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto",
  },

  [`& .${classes.content}`]: {
    overflowY: "auto",
    flex: "1 1 auto",
  },
}));

const Dashboard = (props) => {
  const { route } = props;

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <Root className={classes.root}>
      <TopBar
        route={route}
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          />
        </ErrorBoundary>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
              {renderRoutes(route.routes)}
            </ErrorBoundary>
          </Suspense>
        </main>
      </div>
    </Root>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
