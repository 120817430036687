import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import GeneralOptions from "./widgets/options/GeneralOptions/GeneralOptions";
import CustomChatBotMessage from "./components/CustomChatBotMessage/CustomChatBotMessage";
const botName = "ChatBot";

const config = {
  botName: botName,
  lang: "no",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#802e87",
    },
    chatButton: {
      backgroundColor: "#802e87",
    },
  },
  initialMessages: [
    createChatBotMessage(
      `Hola soy ${botName}, ¿en qué puedo ayudarte?. Puedes hacerme preguntas como las siguientes:`,
      {
        widget: "options",
      },
    ),
  ],
  state: {},
  customComponents: {
    botChatMessage: (props) => <CustomChatBotMessage {...props} />,
  },
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <GeneralOptions {...props} />,
      mapStateToProps: ["state"],
    },
  ],
};

export default config;
