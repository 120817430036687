import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Drawer,
  colors,
  Divider,
  Paper,
  Avatar,
  Typography,
} from "@mui/material";
import { Tooltip, Hidden } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import useRouter from "utils/useRouter";
import { Navigation } from "components";
import navigationConfig from "./navigationConfig";

const PREFIX = "NavBar";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  profile: `${PREFIX}-profile`,
  avatar: `${PREFIX}-avatar`,
  name: `${PREFIX}-name`,
  divider: `${PREFIX}-divider`,
  navigation: `${PREFIX}-navigation`,
  stars: `${PREFIX}-stars`,
  trialButton: `${PREFIX}-trialButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: "100%",
    overflowY: "auto",
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.profile}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },

  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60,
  },

  [`& .${classes.name}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.navigation}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.stars}`]: {
    display: "flex",
  },

  [`& .${classes.trialButton}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    "&:hover": {
      backgroundColor: colors.blue[900],
    },
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const router = useRouter();
  const role = localStorage.getItem("role");
  const userData = useSelector((state) => state.userData.serverUserData);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "1");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname, openMobile, onMobileClose]);

  let stars = 0;
  if (userData.profile) {
    stars = Math.min(userData.profile.stars || 0, 2);
  }

  const navigationOptions = navigationConfig[role];

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          src={userData.avatar}
          to={"/account"}
        />
        <Typography className={classes.name} variant="h4">
          {userData.first_name} {userData.last_name}
        </Typography>
        {role === "patient" ? (
          <Tooltip title="Estas son las estrellas que has conseguido, puedes obtener hasta 2 estrellas diarias, una por contestar el autorreporte y otra por completar tus tareas.">
            <div className={classes.stars}>
              <Typography variant="h4">{stars}</Typography>
              {Array(stars)
                .fill(0)
                .map((_, i) => (
                  <StarIcon
                    key={"filled-star-" + i}
                    style={{ color: "#802e87" }}
                  />
                ))}
              {Array(2 - stars)
                .fill(0)
                .map((_, i) => (
                  <StarIcon
                    key={"empty-star-" + i}
                    style={{ color: "#c9c3c9" }}
                  />
                ))}
            </div>
          </Tooltip>
        ) : role === "professional" ? (
          <Typography variant="body2">{userData.profile.specialty}</Typography>
        ) : (
          <Typography variant="body2">Administrador</Typography>
        )}
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        <Navigation
          component="div"
          title={navigationOptions.title}
          pages={navigationOptions.pages}
        />
      </nav>
    </div>
  );

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Root>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
