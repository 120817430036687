import React from "react";
import { styled } from "@mui/material/styles";
import { matchPath } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { List, Typography } from "@mui/material";

import useRouter from "utils/useRouter";
import { NavigationListItem } from "./components";

const PREFIX = "Navigation";

const classes = {
  root: `${PREFIX}-root`,
};

const NavigationList = (props) => {
  const { pages, ...rest } = props;

  return (
    <List>
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        [],
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = (props) => {
  const { router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false,
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>,
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />,
    );
  }

  return items;
};

const Navigation = (props) => {
  const { title, pages, className, component: Component, ...rest } = props;

  const StyledComponent = styled(Component)(({ theme }) => ({
    [`&.${classes.root}`]: {
      marginBottom: theme.spacing(3),
    },
  }));

  const router = useRouter();

  return (
    <StyledComponent {...rest} className={clsx(classes.root, className)}>
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList depth={0} pages={pages} router={router} />
    </StyledComponent>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default Navigation;
