// in ActionProvider.jsx
import { toggleChatbotModal } from "actions/chatbot";
import { connect } from "react-redux";
import React, { useEffect, useRef } from "react";
import { askChatbot } from "utils/post/askChatbot";

const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  toggleChatbotModal,
}) => {
  const isComponentMounted = useRef(true);
  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);
  const openChatModal = (initialMessage) => {
    toggleChatbotModal(true, initialMessage);
  };

  const handleOptions = () => {
    const botMessage = createChatBotMessage(
      "Estas son las opciones disponibles: ",
      {
        widget: "options",
      },
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleCompleteAutoreport = () => {
    openChatModal("Quiero responder el autoreporte");
    const botMessage = createChatBotMessage("Cargando autoreporte ...");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleChronicPain = () => {
    openChatModal("Quiero consultar sobre el dolor crónico");
    const botMessage = createChatBotMessage(
      "Cargando información sobre el dolor crónico ...",
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDefault = async (userMessage) => {
    const id = await localStorage.getItem("id");
    const messageToChatbot = {
      user_id: id,
      messages: userMessage,
      thread_id: "710",
    };

    const loadingMessage = createChatBotMessage(
      "Se está cargando la respuesta. Por favor espere ...",
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, loadingMessage],
    }));

    try {
      const response = await askChatbot(messageToChatbot);
      const botMessage = createChatBotMessage(response);
      if (isComponentMounted.current) {
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      }
    } catch (error) {
      console.error("Error fetching response:", error);
      const errorMessage = createChatBotMessage(
        "Lo siento, ha ocurrido un error al cargar la respuesta.",
      );

      // Check if component is still mounted before updating state
      if (isComponentMounted.current) {
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, errorMessage],
        }));
      }
    }
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleOptions,
            handleCompleteAutoreport,
            handleChronicPain,
            handleDefault,
          },
        });
      })}
    </div>
  );
};

export default connect(null, { toggleChatbotModal })(ActionProvider);
