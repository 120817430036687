import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  colors,
} from "@mui/material";

const PREFIX = "CuestionarioCard";

const classes = {
  root: `${PREFIX}-root`,
  consider_margin: `${PREFIX}-consider_margin`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  description: `${PREFIX}-description`,
  tags: `${PREFIX}-tags`,
  learnMoreButton: `${PREFIX}-learnMoreButton`,
  likedButton: `${PREFIX}-likedButton`,
  shareButton: `${PREFIX}-shareButton`,
  details: `${PREFIX}-details`,
  centrar: `${PREFIX}-centrar`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.consider_margin}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.header}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.content}`]: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },

  [`& .${classes.description}`]: {
    padding: theme.spacing(2, 3, 1, 3),
  },

  [`& .${classes.tags}`]: {
    padding: theme.spacing(0, 3, 1, 3),
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },

  [`& .${classes.learnMoreButton}`]: {
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.likedButton}`]: {
    color: colors.red[600],
  },

  [`& .${classes.shareButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.details}`]: {
    padding: theme.spacing(1, 3),
  },

  [`& .${classes.centrar}`]: {
    margin: "auto",
  },
}));

const CuestionarioCard = (props) => {
  const { project, className, ...rest } = props;

  console.log(project);

  const link_to =
    "/cuestionario/" + project.questionaryID + "/" + project.id + "/";

  return (
    <StyledCard {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        className={classes.header}
        disableTypography
        title={
          <Link
            color="textPrimary"
            component={RouterLink}
            to={link_to}
            variant="h5"
          >
            {project.questionary_name}
          </Link>
        }
      />
      <CardContent className={classes.content}>
        <Divider className={classes.consider_margin} />
        <div className={classes.details}>
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          ></Grid>
          <Grid
            alignItems="center"
            container
            justify="space-between"
            spacing={3}
          >
            <Grid item className={classes.centrar}>
              <Button
                className={classes.learnMoreButton}
                component={RouterLink}
                size="small"
                color="secondary"
                to={link_to}
              >
                Responder Cuestionario
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </StyledCard>
  );
};

CuestionarioCard.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
};

export default CuestionarioCard;
