const bodyParts = {
  frente_izq: {
    selected: false,
    value: "frente_izq",
    style: "mapdivpath",
    id: 1,
  },
  frente_der: {
    selected: false,
    value: "frente_der",
    style: "mapdivpath",
    id: 2,
  },
  cuello_frontal: {
    selected: false,
    value: "cuello_frontal",
    style: "mapdivpath",
    id: 3,
  },
  cuello_atras: {
    selected: false,
    value: "cuello_atras",
    style: "mapdivpath",
    id: 4,
  },
  pectoral_izq: {
    selected: false,
    value: "pectoral_izq",
    style: "mapdivpath",
    id: 5,
  },
  pectoral_der: {
    selected: false,
    value: "pectoral_der",
    style: "mapdivpath",
    id: 6,
  },
  brazo_izq: {
    selected: false,
    value: "brazo_izq",
    style: "mapdivpath",
    id: 7,
  },
  brazo_der: {
    selected: false,
    value: "brazo_der",
    style: "mapdivpath",
    id: 8,
  },
  costado_der: {
    selected: false,
    value: "costado_der",
    style: "mapdivpath",
    id: 9,
  },
  costado_izq: {
    selected: false,
    value: "costado_izq",
    style: "mapdivpath",
    id: 10,
  },
  abdomen_der: {
    selected: false,
    value: "abdomen_der",
    style: "mapdivpath",
    id: 11,
  },
  abdomen_izq: {
    selected: false,
    value: "abdomen_izq",
    style: "mapdivpath",
    id: 12,
  },
  antebrazo_der: {
    selected: false,
    value: "antebrazo_der",
    style: "mapdivpath",
    id: 13,
  },
  antebrazo_izq: {
    selected: false,
    value: "antebrazo_izq",
    style: "mapdivpath",
    id: 14,
  },
  muslo_izq: {
    selected: false,
    value: "muslo_izq",
    style: "mapdivpath",
    id: 15,
  },
  mano_izq: {
    selected: false,
    value: "mano_izq",
    style: "mapdivpath",
    id: 16,
  },
  mano_der: {
    selected: false,
    value: "mano_der",
    style: "mapdivpath",
    id: 17,
  },
  palma_izq: {
    selected: false,
    value: "palma_izq",
    style: "mapdivpath",
    id: 18,
  },
  palma_der: {
    selected: false,
    value: "palma_der",
    style: "mapdivpath",
    id: 19,
  },
  pubis: {
    selected: false,
    value: "pubis",
    style: "mapdivpath",
    id: 20,
  },
  muslo_der: {
    selected: false,
    value: "muslo_der",
    style: "mapdivpath",
    id: 21,
  },
  rodilla_der: {
    selected: false,
    value: "rodilla_der",
    style: "mapdivpath",
    id: 22,
  },
  rodilla_izq: {
    selected: false,
    value: "rodilla_izq",
    style: "mapdivpath",
    id: 23,
  },
  pierna_izq: {
    selected: false,
    value: "pierna_izq",
    style: "mapdivpath",
    id: 24,
  },
  pierna_der: {
    selected: false,
    value: "pierna_der",
    style: "mapdivpath",
    id: 25,
  },
  empeine_der: {
    selected: false,
    value: "empeine_der",
    style: "mapdivpath",
    id: 26,
  },
  empeine_izq: {
    selected: false,
    value: "empeine_izq",
    style: "mapdivpath",
    id: 27,
  },
  cabeza_der: {
    selected: false,
    value: "cabeza_der",
    style: "mapdivpath",
    id: 28,
  },
  cabeza_izq: {
    selected: false,
    value: "cabeza_izq",
    style: "mapdivpath",
    id: 29,
  },
  hombro_izq: {
    selected: false,
    value: "hombro_izq",
    style: "mapdivpath",
    id: 30,
  },
  hombro_der: {
    selected: false,
    value: "hombro_der",
    style: "mapdivpath",
    id: 31,
  },
  dorsal_der: {
    selected: false,
    value: "dorsal_der",
    style: "mapdivpath",
    id: 32,
  },
  dorsal_izq: {
    selected: false,
    value: "dorsal_izq",
    style: "mapdivpath",
    id: 33,
  },
  tricep_der: {
    selected: false,
    value: "tricep_der",
    style: "mapdivpath",
    id: 34,
  },
  tricep_izq: {
    selected: false,
    value: "tricep_izq",
    style: "mapdivpath",
    id: 35,
  },
  lumbar_der: {
    selected: false,
    value: "lumbar_der",
    style: "mapdivpath",
    id: 36,
  },
  lumbar_izq: {
    selected: false,
    value: "lumbar_izq",
    style: "mapdivpath",
    id: 37,
  },
  coxis: {
    selected: false,
    value: "coxis",
    style: "mapdivpath",
    id: 38,
  },
  nalga_der: {
    selected: false,
    value: "nalga_der",
    style: "mapdivpath",
    id: 39,
  },
  nalga_izq: {
    selected: false,
    value: "nalga_izq",
    style: "mapdivpath",
    id: 40,
  },
  bicep_fem_der: {
    selected: false,
    value: "bicep_fem_der",
    style: "mapdivpath",
    id: 41,
  },
  bicep_fem_izq: {
    selected: false,
    value: "bicep_fem_izq",
    style: "mapdivpath",
    id: 42,
  },
  gemelo_der: {
    selected: false,
    value: "gemelo_der",
    style: "mapdivpath",
    id: 43,
  },
  gemelo_izq: {
    selected: false,
    value: "gemelo_izq",
    style: "mapdivpath",
    id: 44,
  },
  tobillo_der: {
    selected: false,
    value: "tobillo_der",
    style: "mapdivpath",
    id: 45,
  },
  tobillo_izq: {
    selected: false,
    value: "tobillo_izq",
    style: "mapdivpath",
    id: 46,
  },
  talon_der: {
    selected: false,
    value: "talon_der",
    style: "mapdivpath",
    id: 47,
  },
  talon_izq: {
    selected: false,
    value: "talon_izq",
    style: "mapdivpath",
    id: 48,
  },
  antebrazo_pos_der: {
    selected: false,
    value: "antebrazo_pos_der",
    style: "mapdivpath",
    id: 49,
  },
  antebrazo_pos_izq: {
    selected: false,
    value: "antebrazo_pos_izq",
    style: "mapdivpath",
    id: 50,
  },
};

export default bodyParts;
