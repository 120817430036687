import React, { Suspense } from "react";
import { styled } from "@mui/material/styles";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@mui/material";

const PREFIX = "Error";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("main")(() => ({
  [`&.${classes.root}`]: {
    height: "100%",
  },
}));

const Error = (props) => {
  const { route } = props;

  return (
    <Root className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        {renderRoutes(route.routes)}
      </Suspense>
    </Root>
  );
};

Error.propTypes = {
  route: PropTypes.object,
};

export default Error;
