import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const PREFIX = "EmptyList";

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    textAlign: "center",
    padding: theme.spacing(3),
  },

  [`& .${classes.image}`]: {
    height: 240,
    backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
    backgroundPositionX: "right",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

const EmptyList = (props) => {
  const { className, ...rest } = props;

  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <div className={classes.image} />
      <Typography variant="h4">There's nothing here...</Typography>
    </Root>
  );
};

EmptyList.propTypes = {
  className: PropTypes.string,
};

export default EmptyList;
