export function checkResponse(response) {
  if (response.status === 401) {
    alert("Su sesión ha expirado");
    localStorage.clear();
    window.location.assign("/auth/login");
  } else if (response.status === 403) {
    throw new Error("Forbidden");
  } else {
    return true;
  }
}

export default checkResponse;
