import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider as StoreProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "./theme";
import { configureStore } from "./store";
import Routes from "./routes";
import { ScrollReset } from "./components";
import CustomChatBot from "Bot/CustomChatBot";

import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./assets/scss/index.scss";
import { ErrorBoundary } from "react-error-boundary";

const store = configureStore();
const history = createBrowserHistory();

const App = () => {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Router history={history}>
              <ScrollReset />
              <Routes />
              <CustomChatBot />
            </Router>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
