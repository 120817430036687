// Este archivo pertenece a la implementación de un chatbot en la aplicación de ZmartBoard de https://zmartboard.cloud/
export const TOGGLE_CHATBOT_MODAL = "TOGGLE_CHATBOT_MODAL";

export const toggleChatbotModal = (isShown, initialMessage = null) => ({
  type: TOGGLE_CHATBOT_MODAL,
  payload: {
    isShown,
    initialMessage,
  },
});
