import { combineReducers } from "redux";

//import sessionReducer from './sessionReducer';
import chatbotReducer from "./chatbotReducer";
import authReducer from "./authReducer";
import userDataReducer from "./userDataReducer";
const rootReducer = combineReducers({
  chatbot: chatbotReducer,
  session: authReducer,
  userData: userDataReducer,
});

export default rootReducer;
