import React, { useState } from "react";
import Chatbot from "react-chatbot-kit";
import ChatModal from "./components/ChatModal/ChatModal";
import config from "./config";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import { ReactComponent as ButtonIcon } from "../assets/icons/robot.svg";
import { useSelector } from "react-redux";

import "react-chatbot-kit/build/main.css";
import "./styles/CustomChatbot.css";

function CustomChatBot() {
  const loggingIn = useSelector((state) => state.session.loggingIn);
  const [showChatbot, toggleChatbot] = useState(false);
  const saveMessages = (messages, HTMLString) => {
    localStorage.setItem("chat_messages", JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages"));
    return messages;
  };
  return (
    <>
      <div className="app-chatbot-container">
        {loggingIn && showChatbot ? (
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageHistory={loadMessages()}
            messageParser={MessageParser}
            saveMessages={saveMessages}
            headerText={`Conversando con ${config.botName}`}
            placeholderText="Escriba su mensaje"
          />
        ) : (
          ""
        )}
      </div>
      {loggingIn && (
        <button
          className="app-chatbot-button"
          onClick={() => toggleChatbot((prev) => !prev)}
        >
          <ButtonIcon className="app-chatbot-button-icon" />
        </button>
      )}
      <ChatModal modalType={"standard"} />
    </>
  );
}

export default CustomChatBot;
