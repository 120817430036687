import { URL } from "../url";
import { checkResponse } from "../checkResponse";

export async function createArticleId(name, description, accessToken) {
  //const accessToken = await localStorage.getItem('currentToken')
  const response = await fetch(`${URL}/articles/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    body: JSON.stringify({
      article_name: name,
      text: description,
    }),
  });
  const res = await response.json();
  if (checkResponse(response)) {
    return res;
  }
}
