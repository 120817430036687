/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Fab,
  CircularProgress,
  colors,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import InputIcon from "@mui/icons-material/Input";
import MenuIcon from "@mui/icons-material/Menu";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import axios from "utils/axios";
import { PricingModal } from "components";
import { logOut } from "actions";
import { useHistory } from "react-router";

const PREFIX = "TopBar";

const classes = {
  root: `${PREFIX}-root`,
  flexGrow: `${PREFIX}-flexGrow`,
  fabProgress: `${PREFIX}-fabProgress`,
  loader: `${PREFIX}-loader`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInput: `${PREFIX}-searchInput`,
  searchPopper: `${PREFIX}-searchPopper`,
  searchPopperContent: `${PREFIX}-searchPopperContent`,
  trialButton: `${PREFIX}-trialButton`,
  trialIcon: `${PREFIX}-trialIcon`,
  notificationsButton: `${PREFIX}-notificationsButton`,
  notificationsBadge: `${PREFIX}-notificationsBadge`,
  logoutButton: `${PREFIX}-logoutButton`,
  signOutButton: `${PREFIX}-signOutButton`,
  logoutIcon: `${PREFIX}-logoutIcon`,
  mainIcon: `${PREFIX}-mainIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    boxShadow: true,
  },

  [`& .${classes.flexGrow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.fabProgress}`]: {
    color: "#802e87",
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },

  [`& .${classes.loader}`]: {
    position: "absolute",
    bottom: "5%",
    right: "5%",
    zIndex: 1,
  },

  [`& .${classes.search}`]: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.searchIcon}`]: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },

  [`& .${classes.searchInput}`]: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },

  [`& .${classes.searchPopper}`]: {
    zIndex: theme.zIndex.appBar + 100,
  },

  [`& .${classes.searchPopperContent}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.trialButton}`]: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: "#802e87",
    "&:hover": {
      backgroundColor: colors.blue[900],
    },
  },

  [`& .${classes.trialIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.notificationsButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.notificationsBadge}`]: {
    backgroundColor: colors.orange[600],
  },

  [`& .${classes.logoutButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.signOutButton}`]: {
    marginLeft: theme.spacing(1),
    color: "#000000",
  },

  [`& .${classes.logoutIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.mainIcon}`]: {
    height: 40,
    marginLeft: theme.spacing(3),
  },
}));

const role = localStorage.getItem("role");

const TopBar = (props) => {
  const { onOpenNavBarMobile, route, className, ...rest } = props;

  const history = useHistory();

  const {
    aparecen,
    cargandon,
    successn,
    setAparecen,
    setCargandon,
    setSuccessn,
  } = route;
  const dispatch = useDispatch();
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {}, []);

  const handleLogout = () => {
    if (window.confirm("¿Estas seguro/a que deseas cerrar sesión?")) {
      localStorage.clear();
      dispatch(logOut());
      history.push("/");
    }
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };
  const handleButtonClick = () => {
    if (successn) {
      setSuccessn(false);
      setAparecen(false);
      setCargandon(true);
    }
  };
  const handleWebPage = () => {
    var toShop = "https://agenda.ucchristus.cl/";
    window.open(toShop);
  };

  let button;
  let button2;
  if (role === "patient") {
    button = (
      <Button
        className={classes.trialButton}
        component={RouterLink}
        variant="contained"
        to="/selfreport"
      >
        {" "}
        <ListAltIcon className={classes.trialIcon} />
        Autoreporte
      </Button>
    );
    button2 = (
      <Button
        className={classes.trialButton}
        variant="contained"
        onClick={handleWebPage}
      >
        <EventIcon className={classes.trialIcon} /> Reserva Médica
      </Button>
    );
  } else {
    button = (
      <Button
        className={classes.trialButton}
        component={RouterLink}
        variant="contained"
        to="/management/customers"
      >
        {" "}
        <PeopleIcon className={classes.trialIcon} />
        Mis Pacientes
      </Button>
    );
    button2 = "";
  }

  return (
    <Root>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        color="primary"
      >
        <Toolbar>
          <RouterLink to="/">
            <img
              className={classes.mainIcon}
              alt="Logo"
              src="/images/logos/logo--red.png"
            />
          </RouterLink>
          <div className={classes.flexGrow} />

          <Hidden smDown>{button}</Hidden>
          <Hidden smDown>{button2}</Hidden>

          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon className={classes.logoutIcon} name="logout" />
            <Hidden mdDown>cerrar</Hidden>
          </Button>

          <Hidden lgUp>
            <IconButton
              name="navbar"
              color="inherit"
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        <PricingModal onClose={handlePricingClose} open={pricingModalOpen} />
      </AppBar>
    </Root>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
