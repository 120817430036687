import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Toolbar } from "@mui/material";

const PREFIX = "Topbar";

const classes = {
  root: `${PREFIX}-root`,
  mainLogo: `${PREFIX}-mainLogo`,
};

const StyledAppBar = styled(AppBar)(() => ({
  [`&.${classes.root}`]: {
    boxShadow: "true",
  },

  [`& .${classes.mainLogo}`]: {
    height: 40,
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;

  return (
    <StyledAppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            className={classes.mainLogo}
            alt="Logo"
            src="/images/logos/logo--red.png"
          />
        </RouterLink>
      </Toolbar>
    </StyledAppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
