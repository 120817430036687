import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { colors } from "@mui/material";
import ReactPaginate from "react-paginate";

const PREFIX = "Paginate";

const classes = {
  root: `${PREFIX}-root`,
  active: `${PREFIX}-active`,
  activeLink: `${PREFIX}-activeLink`,
  break: `${PREFIX}-break`,
  breakLink: `${PREFIX}-breakLink`,
  disabled: `${PREFIX}-disabled`,
  next: `${PREFIX}-next`,
  nextLink: `${PREFIX}-nextLink`,
  page: `${PREFIX}-page`,
  pageLink: `${PREFIX}-pageLink`,
  previous: `${PREFIX}-previous`,
  previousLink: `${PREFIX}-previousLink`,
};

const StyledReactPaginate = styled(ReactPaginate)(({ theme }) => ({
  [`& .${classes.root}`]: {
    ...theme.typography.button,
    listStyle: "none",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.active}`]: {},
  [`& .${classes.activeLink}`]: {},
  [`& .${classes.break}`]: {},
  [`& .${classes.breakLink}`]: {},
  [`& .${classes.disabled}`]: {},

  [`& .${classes.next}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.nextLink}`]: {
    padding: "6px 16px",
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
    },
  },

  [`& .${classes.page}`]: {},

  [`& .${classes.pageLink}`]: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    outline: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "block",
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
    "&$activeLink": {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.previous}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.previousLink}`]: {
    padding: "6px 16px",
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
    },
  },
}));

const Paginate = (props) => {
  const { pageCount, pageRangeDisplayed, onPageChange, className, ...rest } =
    props;

  return (
    <ReactPaginate
      activeClassName={classes.active}
      activeLinkClassName={classes.activeLink}
      breakClassName={classes.break}
      breakLabel="..."
      breakLinkClassName={classes.breakLink}
      containerClassName={clsx(classes.root, className)}
      disabledClassName={classes.disabled}
      marginPagesDisplayed={2}
      nextClassName={classes.next}
      nextLabel="next"
      nextLinkClassName={classes.nextLink}
      onPageChange={onPageChange}
      pageClassName={classes.page}
      pageCount={pageCount}
      pageLinkClassName={classes.pageLink}
      pageRangeDisplayed={pageRangeDisplayed}
      previousClassName={classes.previous}
      previousLabel="previous"
      previousLinkClassName={classes.previousLink}
      subContainerClassName="pages pagination"
      {...rest}
    />
  );
};

Paginate.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number.isRequired,
};

Paginate.defaultProps = {
  onPageChange: () => {},
  pageRangeDisplayed: 5,
};

export default Paginate;
