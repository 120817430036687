// Este archivo esta basado en el código correspondiente a la implementación de un chatbot en la aplicación de ZmartBoard de https://zmartboard.cloud/
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogContentText } from "@mui/material";
import { DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { connect } from "react-redux";
import { toggleChatbotModal } from "actions/chatbot";
import { askChatbot } from "utils/post/askChatbot";
import "../styles/ChatModal.css";
import CircularProgress from "@mui/material/CircularProgress";

function ChatModal({
  isChatbotModalShown,
  initialMessage,
  toggleChatbotModal,
}) {
  const [message, setMessage] = React.useState("");
  const [chatMessages, setChatMessages] = React.useState([
    {
      message:
        "Ingrese su consulta, para obtener mejores resultados trate de ser lo más detallado que pueda",
      role: "ChatBot",
      symbol: "🤖",
    },
  ]);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleSendQuery = async (query) => {
    setMessage("");
    const newChatMessages = [
      ...chatMessages,
      { message: query, role: "Usuario", symbol: "👤" },
    ];
    setChatMessages(newChatMessages);
    setIsLoading(true);
    const id = await localStorage.getItem("id");
    const messageToChatbot = {
      user_id: id,
      messages: query,
      thread_id: "710",
    };
    try {
      const response = await askChatbot(messageToChatbot);
      if (response) {
        newChatMessages.push({
          message: response,
          role: "ChatBot",
          symbol: "🤖",
        });
        setChatMessages(newChatMessages);
      } else {
        throw new Error("No se pudo obtener respuesta del chatbot");
      }
    } catch (error) {
      console.error(error);
      newChatMessages.push({
        message: "Lo siento, no pude procesar tu consulta. Ocurrió un error",
        role: "ChatBot",
        symbol: "🤖",
      });
      setChatMessages(newChatMessages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClose = () => {
    setChatMessages([
      {
        message:
          "Ingrese su consulta, para obtener mejores resultados trate de ser lo más detallado que pueda",
        role: "ChatBot",
        symbol: "🤖",
      },
    ]);
    setMessage("");
    toggleChatbotModal(false, "");
  };

  React.useEffect(() => {
    console.log("Aplicando efecto");
    if (initialMessage) {
      handleSendQuery(initialMessage);
    }
  }, [initialMessage]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={isChatbotModalShown}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className="chatmodal-container"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="chatmodal-title">
          <h3>Conversando con ChatBot</h3>
          <IconButton aria-label="close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M.146 0a.5.5 0 0 1 .708.708L8 7.293 15.146.146a.5.5 0 1 1 .708.708L8.707
                  8l7.147 7.146a.5.5 0 0 1-.708.708L8 8.707l-7.146
                  7.147a.5.5 0 0 1-.708-.708L7.293 8 .146.854A.5.5
                  0 0 1 .146 0z"
              />
            </svg>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="dialogContentText">
          Indicaciones:
          <ul>
            <li>
              El ChatBot te ayudará a resolver tus dudas, pero trata de detallar
              tus consultas.
            </li>
            <li>
              Si deseas enviar un mensaje, escríbelo en el cuadro de texto y
              presiona el botón de enviar.
            </li>
            <li>Si deseas salir, presiona el botón de cerrar.</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText className="dialogContentText">
          <List sx={{ p: 0, overflowY: "auto", flex: "1 0 0", width: "100%" }}>
            {chatMessages.length > 1 &&
              chatMessages.map((chatMessage, index) => (
                <React.Fragment key={index}>
                  {" "}
                  <Paper variant="outlined">
                    <ListItem alignItems="flex-start">
                      <Box sx={{ display: "flex" }}>
                        <ListItemAvatar>
                          <Avatar>{chatMessage.symbol}</Avatar>
                        </ListItemAvatar>
                      </Box>
                      <ListItemText
                        sx={{ m: 0 }}
                        primary={chatMessage.role}
                        secondary={
                          <ReactMarkdown>{chatMessage.message}</ReactMarkdown>
                        }
                      />
                    </ListItem>
                  </Paper>
                  <br />
                </React.Fragment>
              ))}
          </List>
          {isLoading && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress style={{ color: "#802e87" }} />
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <TextField
            onChange={handleMessageChange}
            value={message}
            label="Escriba su mensaje..."
            variant="outlined"
          />
        </FormControl>
        <IconButton
          aria-label="send"
          onClick={() => {
            handleSendQuery(message);
          }}
        >
          <SendIcon fontSize="large" style={{ color: "#802e87" }} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  console.log("Mapeando estado", state);
  return {
    isChatbotModalShown: state.chatbot.isShown,
    initialMessage: state.chatbot.initialMessage,
  };
}

const mapDispatchToProps = {
  toggleChatbotModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatModal);
