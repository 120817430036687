import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
} from "@mui/material";

import { NotificationList, EmptyList } from "./components";

const PREFIX = "NotificationsPopover";

const classes = {
  root: `${PREFIX}-root`,
  actions: `${PREFIX}-actions`,
};

const StyledPopover = styled(Popover)(() => ({
  [`& .${classes.root}`]: {
    width: 350,
    maxWidth: "100%",
  },

  [`& .${classes.actions}`]: {
    backgroundColor: colors.grey[50],
    justifyContent: "center",
  },
}));

const NotificationsPopover = (props) => {
  const { notifications, anchorEl, ...rest } = props;

  return (
    <StyledPopover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications.length > 0 ? (
          <NotificationList notifications={notifications} />
        ) : (
          <EmptyList />
        )}
        <Divider />
        <CardActions className={classes.actions}>
          <Button component={RouterLink} size="small" to="#">
            See all
          </Button>
        </CardActions>
      </div>
    </StyledPopover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NotificationsPopover;
