import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { postSummary } from "utils/post";
import { Typography, Grid, Card } from "@mui/material";

const PREFIX = "Overview";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  item: `${PREFIX}-item`,
  valueContainer: `${PREFIX}-valueContainer`,
  label: `${PREFIX}-label`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.content}`]: {
    padding: 0,
  },

  [`& .${classes.item}`]: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },

  [`& .${classes.valueContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.label}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const Overview = (props) => {
  const { className, ...rest } = props;
  const [lm, setLm] = useState("");
  const [lw, setLw] = useState("");
  const [num, setNum] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("currentToken");
    const id = localStorage.getItem("id");

    async function fetchData() {
      try {
        let { stats } = await postSummary(accessToken, id, "last-month");
        setLm(stats.alerts);
        stats = (await postSummary(accessToken, id, "last-week")).stats;
        setLw(stats.alerts);
        setNum(stats.patients);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <StyledGrid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Alertas de la semana
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{lw}</Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Alertas del mes
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{lm}</Typography>
          </div>
        </Grid>
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography component="h2" gutterBottom variant="overline">
            Total Pacientes
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{num}</Typography>
          </div>
        </Grid>
      </StyledGrid>
    </Card>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
