import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { postSummary, postOverview } from "utils/post";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Typography, Divider, Card, CardHeader, colors } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Grid } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const PREFIX = "SleepBarGraph";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  markdownContainer: `${PREFIX}-markdownContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  formControl: `${PREFIX}-formControl`,
  inputLabel: `${PREFIX}-inputLabel`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3, 3, 6, 3),
  },

  [`& .${classes.divider}`]: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.markdownContainer}`]: {
    maxWidth: 700,
  },

  [`& .${classes.headerTitle}`]: {
    fontSize: 25,
  },

  [`& .${classes.formControl}`]: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    minWidth: 120,
  },
  [`& .${classes.inputLabel}`]: {
    margin: theme.spacing(-1),
  },
}));

const SleepBarGraph = (props) => {
  const { className, patient, idx, ...rest } = props;

  const [source, setSource] = useState("");
  const [time, setTime] = useState("all");
  const metric = "sleep"; // "pain", "goals", "fatigue" -> metricas con un valor
  const accessToken = localStorage.getItem("currentToken");
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  const dataValues = {
    labels: [],
    values: [],
    max: 10,
  };

  const [booleano, setBooleano] = useState(false);
  const [graphData, setGraphData] = useState(dataValues);

  async function fetchData(time_value) {
    if (role === "admin") {
      var answers = await postOverview(accessToken, time_value);
    } else {
      var answers = await postSummary(accessToken, id, time_value);
    }
    const valuesAnswers = answers["stats"][metric]["data"];
    const max = answers["stats"][metric]["answers"];

    const labels = [];
    const values = [];

    for (const [key, value] of Object.entries(valuesAnswers)) {
      labels.unshift(key);
      values.unshift(value);
    }

    const result = {
      labels: labels,
      values: values,
      max: max,
    };
    setGraphData(result);
    setBooleano(true);
    return answers;
  }

  const data = {
    labels: graphData.labels,
    datasets: [
      {
        label: "cantidad de pacientes por horas de sueño",
        data: graphData.values,
        backgroundColor: [
          "rgb(255, 0, 0)",
          "rgb(255, 153, 60)",
          "rgb(255, 186, 0)",
          "rgb(255, 237, 62)",
          "rgb(255, 255, 58)",
          "rgb(177, 213, 58)",
          "rgb(143, 165, 58)",
          "rgb(143, 165, 255)",
          "rgb(54, 162, 235)",
          "rgb(0, 211, 255)",
          "rgb(218, 255, 255)",
        ],
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    indexAxis: "y", // Esto cambia la orientación del gráfico
    scales: {
      x: {
        beginAtZero: true,
        min: 0,
        max: graphData.max,
      },
      y: {
        ticks: {
          step: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    fetch("/docs/GettingStarted.md")
      .then((response) => response.text())
      .then((text) => setSource(text));
    if (!booleano) fetchData(time);
  }, []);

  async function handleChange(event) {
    await setTime(event.target.value);
    fetchData(event.target.value);
  }

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        // action={<GenericMoreButton />}
        title="Resumen de las horas de sueño"
        classes={{
          title: classes.headerTitle,
        }}
      />
      <Divider />
      <Grid container justify="flex-end">
        <FormControl className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-label"
            className={classes.inputLabel}
          >
            {" "}
            Tiempo{" "}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={time}
            onChange={handleChange}
          >
            <MenuItem value={"last-week"}>Ultima semana</MenuItem>
            <MenuItem value={"last-month"}>Ultimo mes</MenuItem>
            <MenuItem value={"last3"}>Ultimos 3 meses</MenuItem>
            <MenuItem value={"all"}>Todo el tiempo</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Typography gutterBottom variant="overline"></Typography>

      <Bar data={data} options={options} />
    </StyledCard>
  );
};
SleepBarGraph.propTypes = {
  className: PropTypes.string,
};

export default SleepBarGraph;
