// Este archivo esta basado en código del GitHub de la librería "react-chatbot-kit" en https://github.com/FredrikOseberg/react-chatbot-kit-docs/tree/master
import React from "react";
import Options from "../Options/Options";

const GeneralOptions = (props) => {
  const options = [
    {
      name: "Responder autoreporte",
      handler: props.actionProvider.handleCompleteAutoreport,
      id: 1,
    },
    {
      name: "Consultar sobre dolor crónico",
      handler: props.actionProvider.handleChronicPain,
      id: 2,
    },
  ];

  return <Options options={options} />;
};

export default GeneralOptions;
