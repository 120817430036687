import { URL } from "../url";
import { checkResponse } from "../checkResponse";
export async function getCurrentUser(accessToken, id) {
  try {
    const response = await fetch(`${URL}/users/${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    });

    const user = await response.json();

    checkResponse(response);
    return {
      id: user.id,
      role: user.Role,
      rut: user.rut,
      first_name: user.first_name,
      last_name: user.last_name,
      dob: user.dob,
      email: user.email,
      avatar: user.image,
      nick: user.nick,
      address: user.address,
      cellphone_num: user.cellphone_num,
      diagnosis: user.diagnosis || [],
      specialists: [],
      tags: [],
      base_pain: user.base_pain,
      periodicity: user.periodicity,
      answered_today: user.answered_today,
      is_active: user.is_active,
      messaging: user.messaging,
      profile: {
        specialty: user.specialty_name,
        unread_messages: 0,
        unread_alerts: 0,
        stars: user.stars,
        stars_date: user.stars_date,
      },
      body_map: user.body_map,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}
