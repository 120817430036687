import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { postSummary } from "utils/post";
import bodyParts from "views/Profile/components/QBody/bodyParts";
import paths from "views/Profile/components/QBody/paths";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tooltip,
  Divider,
} from "@mui/material";
const PREFIX = "BodyGraph";

const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  markdownContainer: `${PREFIX}-markdownContainer`,
  headerTitle: `${PREFIX}-headerTitle`,
  formControl: `${PREFIX}-formControl`,
  slider: `${PREFIX}-slider`,
  option: `${PREFIX}-option`,
  tag: `${PREFIX}-tag`,
  optionRadio: `${PREFIX}-optionRadio`,
  optionDetails: `${PREFIX}-optionDetails`,
  mapdivpath: `${PREFIX}-mapdivpath`,
  chips: `${PREFIX}-chips`,
  top: `${PREFIX}-top`,
  pado: `${PREFIX}-pado`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3, 3, 6, 3),
    paddingBottom: theme.spacing(0),
    height: "100%",
  },

  [`& .${classes.headerTitle}`]: {
    fontSize: 25,
  },

  [`& .${classes.pado}`]: {
    padding: theme.spacing(0),
    paddingBottom: "0px",
  },
}));

function useBodyPartsCount() {
  const [parts, setParts] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("currentToken");
        const id = localStorage.getItem("id");
        const response = await postSummary(token, id, "all");
        setParts(response.stats["body-map"].data);
      } catch (error) {
        console.error(error);
        setParts({});
      }
    }
    fetchData();
  }, []);

  return parts;
}

const BodyGraph = () => {
  const counts = useBodyPartsCount();

  console.log(counts);
  console.log(bodyParts);

  return (
    <StyledCard className={classes.root}>
      <CardHeader
        title="Zonas de dolor"
        classes={{
          title: classes.headerTitle,
        }}
      />
      <Divider />
      <br></br>
      <CardContent className={classes.pado} style={{ padding: "0px" }}>
        <Grid container spacing={0} className={classes.top}>
          <Grid item xs={12} className={classes.pado}>
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 1842.000000 1378.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,1378.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                {Object.entries(bodyParts).map(([key, part]) => (
                  <Tooltip
                    title={
                      counts[part.id] ? `${counts[part.id]} pacientes` : ""
                    }
                    key={key}
                  >
                    <a>
                      <path
                        key={key}
                        className={classes.mapdivpath}
                        d={paths[key].path}
                        fill={"#802e87"}
                      />
                    </a>
                  </Tooltip>
                ))}
              </g>
              <g
                fontSize="50"
                fontFamily="sans-serif"
                fill="white"
                stroke="none"
                textAnchor="middle"
              >
                <text x="405" y="70">
                  {counts[bodyParts.frente_der.id]}
                </text>
                <text x="455" y="70">
                  {counts[bodyParts.frente_izq.id]}
                </text>
                <text x="430" y="220">
                  {counts[bodyParts.cuello_frontal.id]}
                </text>
                <text x="360" y="300">
                  {counts[bodyParts.pectoral_der.id]}
                </text>
                <text x="500" y="300">
                  {counts[bodyParts.pectoral_izq.id]}
                </text>
                <text x="360" y="420">
                  {counts[bodyParts.costado_der.id]}
                </text>
                <text x="500" y="420">
                  {counts[bodyParts.costado_izq.id]}
                </text>
                <text x="370" y="540">
                  {counts[bodyParts.abdomen_der.id]}
                </text>
                <text x="490" y="540">
                  {counts[bodyParts.abdomen_izq.id]}
                </text>
                <text x="430" y="650">
                  {counts[bodyParts.pubis.id]}
                </text>
                <text x="240" y="400">
                  {counts[bodyParts.brazo_der.id]}
                </text>
                <text x="630" y="400">
                  {counts[bodyParts.brazo_izq.id]}
                </text>
                <text x="170" y="500">
                  {counts[bodyParts.antebrazo_der.id]}
                </text>
                <text x="700" y="500">
                  {counts[bodyParts.antebrazo_izq.id]}
                </text>
                <text x="70" y="650">
                  {counts[bodyParts.palma_der.id]}
                </text>
                <text x="800" y="650">
                  {counts[bodyParts.palma_izq.id]}
                </text>
                <text x="370" y="740">
                  {counts[bodyParts.muslo_der.id]}
                </text>
                <text x="490" y="740">
                  {counts[bodyParts.muslo_izq.id]}
                </text>
                <text x="370" y="930">
                  {counts[bodyParts.rodilla_der.id]}
                </text>
                <text x="490" y="930">
                  {counts[bodyParts.rodilla_izq.id]}
                </text>
                <text x="370" y="1080">
                  {counts[bodyParts.pierna_der.id]}
                </text>
                <text x="490" y="1080">
                  {counts[bodyParts.pierna_izq.id]}
                </text>
                <text x="370" y="1260">
                  {counts[bodyParts.empeine_der.id]}
                </text>
                <text x="480" y="1260">
                  {counts[bodyParts.empeine_izq.id]}
                </text>
                {/* freente */}
                <text x="1370" y="70">
                  {counts[bodyParts.cabeza_izq.id]}
                </text>
                <text x="1420" y="70">
                  {counts[bodyParts.cabeza_der.id]}
                </text>
                <text x="1400" y="210">
                  {counts[bodyParts.cuello_atras.id]}
                </text>
                <text x="1330" y="300">
                  {counts[bodyParts.hombro_izq.id]}
                </text>
                <text x="1470" y="300">
                  {counts[bodyParts.hombro_der.id]}
                </text>
                <text x="1330" y="420">
                  {counts[bodyParts.dorsal_izq.id]}
                </text>
                <text x="1470" y="420">
                  {counts[bodyParts.dorsal_der.id]}
                </text>
                <text x="1340" y="520">
                  {counts[bodyParts.lumbar_izq.id]}
                </text>
                <text x="1460" y="520">
                  {counts[bodyParts.lumbar_der.id]}
                </text>
                <text x="1400" y="570">
                  {counts[bodyParts.coxis.id]}
                </text>
                <text x="1215" y="400">
                  {counts[bodyParts.tricep_izq.id]}
                </text>
                <text x="1595" y="400">
                  {counts[bodyParts.tricep_der.id]}
                </text>
                <text x="1170" y="500">
                  {counts[bodyParts.antebrazo_pos_izq.id]}
                </text>
                <text x="1655" y="500">
                  {counts[bodyParts.antebrazo_pos_der.id]}
                </text>
                <text x="1070" y="650">
                  {counts[bodyParts.mano_izq.id]}
                </text>
                <text x="1750" y="650">
                  {counts[bodyParts.mano_der.id]}
                </text>
                <text x="1350" y="650">
                  {counts[bodyParts.nalga_izq.id]}
                </text>
                <text x="1470" y="650">
                  {counts[bodyParts.nalga_der.id]}
                </text>
                <text x="1350" y="800">
                  {counts[bodyParts.bicep_fem_izq.id]}
                </text>
                <text x="1470" y="800">
                  {counts[bodyParts.bicep_fem_der.id]}
                </text>
                <text x="1350" y="1080">
                  {counts[bodyParts.gemelo_izq.id]}
                </text>
                <text x="1460" y="1080">
                  {counts[bodyParts.gemelo_der.id]}
                </text>
                <text x="1350" y="1249" fontSize="35">
                  {counts[bodyParts.tobillo_izq.id]}
                </text>
                <text x="1450" y="1245" fontSize="35">
                  {counts[bodyParts.tobillo_der.id]}
                </text>
                <text x="1350" y="1290" fontSize="35">
                  {counts[bodyParts.talon_izq.id]}
                </text>
                <text x="1460" y="1290" fontSize="35">
                  {counts[bodyParts.talon_der.id]}
                </text>
              </g>
            </svg>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

BodyGraph.propTypes = {
  className: PropTypes.string,
};

export default BodyGraph;
