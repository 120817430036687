import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import "../styles/CustomChatBotMessage.css";
function CustomChatBotMessage({ message, loader }) {
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <React.Fragment>
      <div
        className="react-chatbot-kit-chat-bot-message"
        style={{ backgroundColor: "rgb(128, 46, 135)" }}
      >
        {showMessage ? <ReactMarkdown>{message}</ReactMarkdown> : loader}
        <div
          className="react-chatbot-kit-chat-bot-message-arrow"
          style={{ borderRightColor: "rgb(128, 46, 135)" }}
        ></div>
      </div>
    </React.Fragment>
  );
}

export default CustomChatBotMessage;
