// Este archivo pertenece a la implementación de un chatbot en la aplicación de ZmartBoard de https://zmartboard.cloud/
import { TOGGLE_CHATBOT_MODAL } from "../actions/chatbot";

const initialState = {
  isShown: false,
  initialMessage: "",
};

const chatbotReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CHATBOT_MODAL:
      return {
        ...state,
        isShown: action.payload.isShown,
        initialMessage: action.payload.initialMessage,
      };
    default:
      return state;
  }
};

export default chatbotReducer;
