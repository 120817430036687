import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MailIcon from "@mui/icons-material/MailOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TaskIcon from "@mui/icons-material/Task";

const navigationConfig = {
  professional: {
    title: "Doctor",
    pages: [
      {
        title: "Dashboard",
        href: "/dashboards/analytics",
        icon: DashboardIcon,
      },
      {
        title: "Mis Pacientes",
        href: "/management/customers",
        icon: PeopleIcon,
      },
      {
        title: "Archivos",
        href: "/archivos/results",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/cuestionariosdoctor",
        icon: ListAltIcon,
      },
      {
        title: "Tareas",
        href: "/tareasdoctor",
        icon: TaskIcon,
      },
      {
        title: "Avisos",
        href: "/mail",
        icon: MailIcon,
      },
      {
        title: "Etiquetas de Pacientes",
        href: "/lista-tags",
        icon: LocalOfferIcon,
      },
      {
        title: "Mi Cuenta",
        href: "/account",
        icon: AccountBoxIcon,
      },
      {
        title: "Invitación Nuevo Paciente",
        icon: PersonAddIcon,
        href: "/new-paciente",
      },
    ],
  },
  admin: {
    title: "Administrador",
    pages: [
      {
        title: "Dashboard",
        href: "/dashboards/analytics",
        icon: DashboardIcon,
      },
      {
        title: "Mis Pacientes",
        href: "/management/customers",
        icon: PeopleIcon,
      },
      {
        title: "Archivos",
        href: "/archivos/results",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/cuestionariosdoctor",
        icon: ListAltIcon,
      },
      {
        title: "Avisos",
        href: "/mail",
        icon: MailIcon,
      },
      {
        title: "Etiquetas de Pacientes",
        href: "/lista-tags",
        icon: LocalOfferIcon,
      },
      {
        title: "Especialidades",
        href: "/specialties",
        icon: GroupAddIcon,
      },
      {
        title: "Mi Cuenta",
        href: "/account",
        icon: AccountBoxIcon,
      },
      {
        title: "Nuevo Usuario",
        icon: PersonAddIcon,
        children: [
          {
            title: "Invitación nuevo Tratante",
            href: "/new-tratante",
          },
          {
            title: "Invitación nuevo Paciente",
            href: "/new-paciente",
          },
        ],
      },
    ],
  },
  patient: {
    title: "Paciente",
    pages: [
      {
        title: "Home",
        href: "/overview",
        icon: HomeIcon,
      },
      {
        title: "Autoreporte",
        href: "/selfreport",
        icon: ListAltIcon,
      },
      {
        title: "Mi Diagnóstico",
        href: "/profile",
        icon: AssignmentIcon,
      },
      // {
      // //   title: "Mis Tareas",
      // //   href: "/tareas",
      // //   icon: TaskIcon
      // // },
      {
        title: "Archivos",
        href: "/files",
        icon: FolderIcon,
      },
      {
        title: "Cuestionarios",
        href: "/questionaries",
        icon: ListAltIcon,
      },
      {
        title: "Avisos",
        href: "/mail",
        icon: MailIcon,
      },
      {
        title: "Mi Cuenta",
        href: "/account",
        icon: AccountBoxIcon,
      },
    ],
  },
};

export default navigationConfig;
